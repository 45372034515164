const config = {
  app: 'portal',
  appVersion: '0.0.45',
  environment: 'uat',

  apiUrl: 'https://api-uat.tagflix.com/v2',
  testApiUrl: 'https://api-uat.tagflix.com/v2',
  appUrl: 'https://uat.portal.tagflix.com',
  publisherSolutionDomain: 'https://cdn.tagflix.com',

  documentationUrl: {
    api: 'https://publisherportal.docs.apiary.io',
    integration: 'https://bit.ly/publisher-solution-integration-guide',
    flowplayer: 'https://bit.ly/2Vcfp54',
    brightcove: 'https://bit.ly/2JGEoac',
    jwplayer: 'http://bit.ly/2QLP7Uz'
  },

  supportTicketUrl: 'https://bit.ly/publisher-portal-support',
  mockRequests: true,
  tagflixPublisherId: '5a29cd7fdd028c1e1c6e91f9',
  maxCharacterCountPerLabel: 25,
  acceptedFileTypesForVideoUpload: ['mp4'],
  facebookAppID: '1828105220754347'
};

export default config;
